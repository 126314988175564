<template>
  <div class="monitoring-items-upload mt-4">
    <b-card>
      <b-row>
      </b-row>
      <b-row class="pb-2">
        <div class="col-auto">
          <b-form-select id="currency" class="mb-2" v-model="status" :options="statusOptions" required
            @change="fetchItemProcess"></b-form-select>
        </div>
        <b-col cols="6">
          <b-form-input type="search" v-model="filter" @input="onSearchItem" placeholder="Type to Search"></b-form-input>
        </b-col>
        <b-col class="justify-content-end pt-1 d-flex align-items-end">
          <span id="button-reload" class="pr-2">
            <button class="btn btn-sm btn-dark" @click="reloadBtn">
              <i class="fa fa-refresh"></i>
            </button>
          </span>
          <span>
            Count :
            <strong class="col-sm-auto">{{ totalRows }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-table id="table-monitoring-item-uploads" sticky-header="600px" :items="items" :fields="fields"
        :busy="isLoading" responsive striped hover v-if="items">
        <template #cell(No)="row">
          {{ (currentPage - 1) * perPage + (row.index + 1) }}
        </template>
        <template #cell(name)="row">
          {{ row.item.items ? row.item.items.name : '' }}
          <strong>
            #{{ row.item.item_id }}
          </strong>
        </template>
        <template #cell(edition_code)="row">
          {{ row.item.items ? row.item.items.edition_code : '' }}
        </template>
        <template #cell(release_date)="row">
          {{ datetime24H(row.item.items.release_date) }}
        </template>
        <template #cell(start_process)="row">
          {{ row.item.start_process_time ? datetime24H(row.item.start_process_time) : 'Not Started' }}
        </template>
        <template #cell(end_process)="row">
          {{ row.item.end_process_time ?  datetime24H(row.item.start_process_time) :'Not Started' }}
        </template>
        <template #cell(created)="row">
          {{ datetime24H(row.item.created)}}
        </template>
        <template #cell(pic)="row">
          {{ row.item.pic_name }}
        </template>
        <template #cell(status)="row">
          <div>
            <span v-if="row.item.status == 10" class="badge badge-success">
              Success
            </span>
            <span v-else-if="row.item.status == 2" class="badge badge-warning">
              Processing
            </span>
            <span v-else-if="row.item.status == 11" class="badge badge-danger">
              Error
            </span>
            <span v-else class="badge badge-info">
              Waiting for processing
            </span>
          </div>
        </template>
        <template #cell(file_sizes)="row">
          {{ row.item.file_size | fileSizeFormat(1) }}
        </template>
        <template #cell(action)="row">
          <div class="d-flex">
            <b-button
              class="mr-1"
              size="sm"
              variant="danger"
              v-b-tooltip.hover
              title="delete this queue"
              @click="onDelete(row.item)"
              v-if="isShowBtnQueue(row.item.status)"
            >
              <i class="fa fa-trash-o"></i>
            </b-button>
            <b-button
              size="sm"
              variant="warning"
              v-b-tooltip.hover
              title="re-queue"
              @click="onReQueue(row.item)"
              v-if="isShowBtnQueue(row.item.status)"
            >
              <i class="fa fa-retweet"></i>
            </b-button>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-dark my-2">
            <b-spinner class="align-middle" variant="dark" type="grow" label="Loading ..">
            </b-spinner>
            Loading ..
          </div>
        </template>
      </b-table>
      <b-row class="justify-content-start ml-1">
        <b-col lg="3">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import constant from "../../store/constant";

const { UPLOAD_PROCESS_STATUS } = constant;
const statusOptions = Object.entries(UPLOAD_PROCESS_STATUS)
  .map(item => ({ value: item[1], text: item[0] }));
export default {
  name: "MonitoringItemUpload",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: null,
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No' },
        {
          key: 'name',
          label: 'Name',
          stickyColumn: true,
        },
        {
          key: 'edition_code',
          label: 'Edition Code'
        }, 
        {
          key: 'status',
          label: 'Status'
        },
        { key: 'pic_name'},
        {
          key: 'release_date',
          label: 'Release Date',
          class: 'text-nowrap',
        },
        {
          key: 'start_process',
          label: 'Start Process Time',
          class: 'text-nowrap'
        },
        {
          key: 'end_process',
          label: 'End Process Time',
          class: 'text-nowrap'
        },
        { key: 'file_sizes', label: 'File Size', class: 'text-nowrap', },
        { key: 'error', label: 'Error' },
        {
          key: 'created',
          label: 'Created',
          class: 'text-nowrap',
        },
        { key: 'action', label: 'action' }
      ],
      status: null,
      statusOptions: [
        { value: null, text: "--Status--" },
        ...statusOptions,
      ]
    };
  },
  computed: {
    ...mapGetters("MonitoringItemUpload", { items: "fetch" }),
    ...mapState({
      isLoading: state => state.MonitoringItemUpload.isLoading,
      isError: state => state.MonitoringItemUpload.isError,
      totalRows: state => state.MonitoringItemUpload.totalRows,
      items: state => state.MonitoringItemUpload.items,
    })
  },
  mounted() {
    this.fetchItemProcess();
  },
  watch: {
    currentPage: function () {
      this.fetchItemProcess();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Items Upload Progress | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  methods: {
    ...mapActions('MonitoringItemUpload', [
      'fetch',
      'search',
      'updateStatus',
      'deleteProcess',
    ]),
    fetchItemProcess() {
      this.filter = null;
      let payload = {
        status: this.status,
        page: this.currentPage,
        limit: this.perPage
      };

      this.fetch(payload);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onSearchItem(data) {
      if (!data) {
        this.fetchItemProcess();
        return;
      }
      this.status = null;
      const payload = {
        name: data,
        limit: 20,
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search(payload);
      }, 600);
    },
    reloadBtn() {
      if (this.filter) {
        this.onSearchItem(this.filter);
      } else {
        this.fetchItemProcess();
      }
    },
    onDelete(data) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Delete",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.processDelete(data.id)
          }
        });
    },
    processDelete(id) {
      this.deleteProcess({ id }).then((result) =>{
        if (result.status == 200) {
          this.$swal.fire('', result.data?.message, 'success');
        } else {
          this.$swal.fire('', 'Opps .. Something is wrong, please try again', 'error');
        }
      }).catch(() => {
          const message = 'Opps .. Something is wrong, please contact your administrator';
          this.$swal('Process Failed', message, 'error');
      }).finally(() => {
        this.reloadBtn();
      });
    },
    onReQueue(data) {
      this.$swal
        .fire({
          title: "are you sure to re-queue this process?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.processQueue(data.id);
          }
        });
    },
    processQueue(id) {
      this.updateStatus({
        id,
        status: UPLOAD_PROCESS_STATUS.new,
      }).then((result) =>{
        if (result.status == 200) {
          this.$swal.fire('', result.data?.message, 'success');
        } else {
          this.$swal.fire('error', 'Opps .. Something is wrong, please try again');
        }
      }).catch(() => {
          const message = 'Opps .. Something is wrong, please contact your administrator';
          this.$swal('Process Failed', message, 'error');
      }).finally(() => {
        this.reloadBtn();        
      });
    },
    isShowBtnQueue(status = 0) {
      return [
        UPLOAD_PROCESS_STATUS.failed,
        UPLOAD_PROCESS_STATUS.processing,
      ].includes(status);
    }
  },
};
</script>

<style scoped>

</style>
